.primaryButton{
    width: 15%;
    height: 10vh;
    margin: 10px;
    background-color: orange !important;
    border: none !important;
}

.primaryButton:hover{
    background-color: purple !important;
}

.secondaryButton{
    width: 15%;
    height: 10vh;
    margin: 10px;
    background-color: gray !important;
    border: none !important;
}

.secondaryButton:hover{
    background-color: purple !important;
}