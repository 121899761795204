.squareButton {
  width: 100%;
  min-height: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
  border: none !important;
  border-radius: 1vw !important;
}

/* Purple background */

.headerConainer{
  background-color: rgb(69, 69, 104) !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.headerConainer h1{
  color: antiquewhite;
  font-size: 24px;
  font-weight: 400;
}

.headerConainer .userName{
  color: antiquewhite;
  font-size: 20px;
  font-weight: 300;
}

.bgPurple {
  background-color: rgb(69, 69, 104) !important;
  border: none !important;
}

.bgPurple:hover {
  background-color: rgb(33, 33, 49) !important;
}

/* Orange nackground */

.bgOrange {
  background-color: rgb(228, 155, 19) !important;
  border: none !important;
}

.bgOrange:hover {
  background-color: rgb(202, 137, 15) !important;
}

.bgGreen {
  background-color: rgb(42, 194, 22) !important;
  border: none !important;
}

.bgGreen:hover {
  background-color: rgb(32, 153, 16) !important;
}


.generalButton{
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
}

.loginImageContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginFormContainer{
  display: flex;
  align-items: center;
}

.loginImage{
  border-radius: 10px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
.loginImageContainer{
  align-items: flex-end;
}

.loginImage{
  width: 80% ;
}

.loginFormContainer{
  align-items: flex-start;
}

  .generalButton{
    height: auto;
  }

  .overFlowScroll{
    overflow-x: scroll;
  }

}


.generalTitle{
 font-size: 24px;
 font-weight: 600;
 color: orangered;
}

.generalSubTitle{
  font-size:18px;
  font-weight: 500;
  color: gray;
 }

 .generalItem{
  font-size:14px;
  font-weight: 300;
  margin-left: 10px;
  line-height: 16px;
 }

 .generalOffCanvasArrow {
  font-size: 24px;
  font-weight: 300;
  color: green;
  transition: font-size 0.3s ease-in-out; /* Added transition property */
}

.generalOffCanvasArrow:hover {
  font-size: 30px;
}


.height100px{
  height: 100px;
}

.font20px{
  font-size: 20px;
}

.signatureComponent{
  border: solid gray;
  width: 100%;
  height: 30vh;
}

