.camView{
    border: 2px solid rgb(65, 212, 20);
    min-width: 40vw;
    border-radius: 20px;
  }
  
  
  .clientItemImage{
    width: 10vw !important;
  }

  @media only screen and (max-width: 600px) {
    .clientItemImage{
        width: 10vw !important;
      }
  }