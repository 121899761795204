
.reportHeader{
    font-size: 50px;
    font-weight: 300;
}


.reportClientName{
    font-size: 30px;
    font-weight: 300;
}

.reportScore{
    font-size: 30px;
    font-weight: 600;
    color: orangered;
}

.reportClientAddress{
    font-size: 20px;
    font-weight: 300;
}

@media only screen and (max-width: 600px) {
    .reportHeader{
        font-size: 25px;
        font-weight: 400;
    }

    
.reportClientName{
    font-size: 20px;
    font-weight: 300;
}

.reportClientAddress{
    font-size: 16px;
    font-weight: 300;
}

  }
  

.headerLogo{
    width: 100%;
}




.reportScoreText{
    font-size: 20px;
    font-weight: 300;
}

.reportAddressRow{
    margin-top: -10px !important;
    padding-top: 0px !important;
}


.reportAreaName{
    font-size: 20px;
    font-weight: 500;
    color: purple; 
}


.reportFaultReportName{
    font-size: 20px;
    font-weight: 500;
    color: orangered 
}

.reportSubAreaName{
    font-size: 18px;
    font-weight: 500;
    color: grey; 
}


.areaContainer{
    margin-bottom: 26px;
}

.imageTDColor{
    background-color: rgb(189, 189, 189) !important;
}

.reportItemImage{
    height: 100% !important;
    width: auto !important;
    max-width: 30%;
    padding: 10px;
}

.itemTable {
    width: 100%;
    margin-top: 12px;
}

.itemTable td {
    background-color: rgb(235, 235, 235) !important;
}

.itemTable  td:nth-child(1) {
    width: 40%;
}

.itemTable td:nth-child(2) {
    width: 20%;
}

.itemTable  td:nth-child(3) {
    width: 40%;
}



.faultReportTable  td:nth-child(1) {
    width: 40%;
}

.faultReportTable  td:nth-child(2) {
    width: 60%;
}

.itemScoreColoredStar{
    color: yellowgreen;
    font-size: 20px
}

.itemScoreUncoloredStar{
    color: gray;
    font-size: 20px
}