.clientFilterButtonsContainer{
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
    overflow-x: auto;
    white-space: nowrap;
}


.clientFilterButton{
    height: 50px;
    margin: 5px;
    border: none !important;
    display: inline-block;
}

.clientFilterButton:hover{
    background-color: green !important;
}

.clientFilterTextContainer{
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}